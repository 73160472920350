import {useEffect, useState, useMemo, useCallback, memo} from 'react';
import {Layer, Source, Popup, Marker} from 'react-map-gl';

const pointLayerStyle = {
  id: 'point',
  type: 'circle',
  paint: {
    'circle-radius': 14,
    'circle-color': ' #3333ff',
  }
};


const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const formatDateTime = (dateTime) => {
    let datetime = new Date(dateTime);
    console.log(dateTime);
    const options = { hour: 'numeric', minute: 'numeric', hour12: false };

    if ( dateTime ){
        return datetime.toLocaleTimeString();
    } else {
        return datetime.toLocaleDateString(
          'ro-RO',
          { month: 'short', day: 'numeric' })
          + ' '
          + dateTime.toLocaleTimeString('ro-RO', options
        );
    }
}

const RouteReportLayer = ({routeReportGeoJSON}) => {
  const [popupState, setPopupState] = useState([]);

  useEffect(() => {
    setPopupState(
      routeReportGeoJSON?.features?.map(() => {return false})
    );
  }, [routeReportGeoJSON])

  const popup = useMemo(() => {
    if ( popupState && popupState.indexOf(true) !== -1) {
      console.log(`Clicked marker ${popupState.indexOf(true)}`);
      const elem = routeReportGeoJSON?.features && routeReportGeoJSON.features?.length > 0
        ? routeReportGeoJSON?.features[popupState.indexOf(true)]
        : undefined;
      if (elem){
        return(
          <Popup
            longitude={elem.geometry?.coordinates[0]}
            latitude={elem.geometry?.coordinates[1]}
            key={`popup-${elem.properties?.id}`}
            anchor="bottom"
            onClick={(e) => {
              setPopupState(popupState.map((v) => false));
            }}
          >
            <div>
              <div className="report-popup-header">
              {toTitleCase(elem.properties.type)}
              </div>
              <div className="report-popup-container">
                {formatDateTime(elem.properties.lastSeenDate)}
                <br />
                {elem.properties.street}
              </div>
            </div>
          </Popup>
        );
      }
    }
  }
  , [routeReportGeoJSON, popupState])


   const reportLayer = useMemo(() => {
     return(
	<>
          <Source
            key={`report-source`}
            id={`report-source`}
            type="geojson"
            data={routeReportGeoJSON}>
            <Layer
              {...pointLayerStyle}
               id="report-layer"/>
          </Source>
       </>
     )
   }, [routeReportGeoJSON])

  const markers = useMemo(() => {
    return routeReportGeoJSON?.features?.map((x,i) =>
      <Marker
        longitude={x.geometry.coordinates[0]}
        latitude={x.geometry.coordinates[1]}
        id={`marker-${i}`}
        key={`marker-${i}`}
        offset={[0.1,0.1]}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setPopupState(popupState?.map((v, p_i) => i === p_i));
        }}
        style={{
          opacity: 0
        }}
        >
      </Marker>
    );
  }, [routeReportGeoJSON, popupState]);

  return (
    <>
    {
      routeReportGeoJSON?.features?.length > 0
      ? <>
	  {
	    reportLayer
	  }
          {
            popup
            ? popup
            : null
          }
          {
            markers && markers.length > 0
            ? markers
            : null
          }
        </>
      : null
    }
    </>
  )
}

export const RouteReportLayerMemo = memo(RouteReportLayer);
